import $ from 'jquery'
$(document).ready(function(){

    $('.burger-btn').on('click',function(e){
        e.preventDefault();
        const opened=$(this).attr('value');
        if(opened!='true'){
            $(this).attr('value','true');
            $(this).children('.rotating-text').children('.r1').html('C<br>C');
            $(this).children('.rotating-text').children('.r2').html('E<br>E');
            $(this).children('.rotating-text').children('.r3').html('R<br>R');
            $(this).children('.rotating-text').children('.r4').html('R<br>R');
            $(this).children('.rotating-text').children('.r5').html('A<br>A');
            $(this).children('.rotating-text').children('.r6').html('R<br>R');
            $(this).children('.rotating-text').css({'color':'white'});
            $('#nav-logo').css({'filter':'brightness(1000%)'});
            $('body').append('<div class="burger"></div>');
            $('.burger-content').css({'display':'initial'});
            $('.burger').animate({'top':'0'},function(){
                $('.burger-links a span').css({'margin-top':'0'});
                $('.burger-footer').css({'opacity':'1'});
            });
        }
        else{
            $(this).attr('value','false');
            $(this).children('.rotating-text').children('.r1').html('M<br>M');
            $(this).children('.rotating-text').children('.r2').html('E<br>E');
            $(this).children('.rotating-text').children('.r3').html('N<br>N');
            $(this).children('.rotating-text').children('.r4').html('U<br>U');
            $(this).children('.rotating-text').children('.r5').html('');
            $(this).children('.rotating-text').children('.r6').html('');
            $('.burger-links a span').css({'margin-top':'-47px'});
            $('.burger-footer').css({'opacity':'0'});
            setTimeout(() => {
                $('.burger').animate({'top':'-100%'},function(){
                    $('.burger-btn').children('.rotating-text').css({'color':'black'});
                    $('#nav-logo').css({'filter':'brightness(100%)'});
                    $('.burger').remove();
                    $('.burger-content').css({'display':'none'});
                    $('.burger-links a span').css({'margin-top':'47px'});
                });
            }, 600);
        }
    });

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
    
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
    
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };
    /*$('#content').bind('scroll', function() {
        var st = $(this).scrollTop();
        console.log('sss');
        if($('.sectionne').isInViewport()){
            $(this).css({'transform':'rotateX(0deg)'})
        }
        if (st > lastScrollTop){
            // downscroll code
            if($('.last-videyo-text').isInViewport()) {
                //
            }
        } else {
            // upscroll code
            if($('.start').isInViewport()) {
                //
            }
        }
        lastScrollTop = st;

    });*/
    
});