<template>
        <div id="main-nav" :class="{sticky:active}">
            <div class="logo">
                <img id="nav-logo" src="./css/assets/imagenessitioAcallis/logo Acallis circ.gif"/>
            </div>
            <!--<a class="rotating-element burger-btn" value="false" href="#">
                <div class="rotating-text">
                    <span class="r1">M<br>M</span>
                    <span class="r2">E<br>E</span>
                    <span class="r3">N<br>N</span>
                    <span class="r4">U<br>U</span>
                    <span class="r5"></span>
                </div>
            </a>-->
            <ul id="nav-links">
                <li class="a-element">
                    <router-link class="rotating-element router-link" to="/">
                        <div class="rotating-text">
                            <span class="r1">I<br>I</span>
                            <span class="r2">N<br>N</span>
                            <span class="r3">I<br>I</span>
                            <span class="r4">C<br>C</span>
                            <span class="r5">I<br>I</span>
                            <span class="r6">O<br>O</span>
                        </div>
                    </router-link>
                </li>
                <li class="a-element">
                    <router-link class="rotating-element router-link" to="/about">
                        <div class="rotating-text">
                            <span class="r1">C<br>C</span>
                            <span class="r2">O<br>O</span>
                            <span class="r3">N<br>N</span>
                            <span class="r4">O<br>O</span>
                            <span class="r5">C<br>C</span>
                            <span class="r6">E<br>E</span>
                            <span class="r7">N<br>N</span>
                            <span class="r8">O<br>O</span>
                            <span class="r9">S<br>S</span>
                        </div>
                    </router-link>
                </li>
                <li class="a-element">
                    <router-link class="rotating-element router-link" to="/servicios">
                        <div class="rotating-text">
                            <span class="r1">S<br>S</span>
                            <span class="r2">E<br>E</span>
                            <span class="r3">R<br>R</span>
                            <span class="r4">V<br>V</span>
                            <span class="r5">I<br>I</span>
                            <span class="r6">C<br>C</span>
                            <span class="r7">I<br>I</span>
                            <span class="r8">O<br>O</span>
                            <span class="r9">S<br>S</span>
                        </div>
                    </router-link>
                </li>
                <li class="a-element">
                    <router-link class="rotating-element router-link" to="/portafolio">
                        <div class="rotating-text">
                            <span class="r1">P<br>P</span>
                            <span class="r2">O<br>O</span>
                            <span class="r3">R<br>R</span>
                            <span class="r4">T<br>T</span>
                            <span class="r5">A<br>A</span>
                            <span class="r6">F<br>F</span>
                            <span class="r7">O<br>O</span>
                            <span class="r8">L<br>L</span>
                            <span class="r9">I<br>I</span>
                            <span class="r10">O<br>O</span>
                        </div>
                    </router-link>
                </li>
                <li class="a-element">
                    <router-link class="rotating-element router-link" to="/contacto">
                        <div class="rotating-text">
                            <span class="r1">C<br>C</span>
                            <span class="r2">O<br>O</span>
                            <span class="r3">N<br>N</span>
                            <span class="r4">T<br>T</span>
                            <span class="r5">A<br>A</span>
                            <span class="r6">C<br>C</span>
                            <span class="r7">T<br>T</span>
                            <span class="r8">O<br>O</span>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>

        <!--<div class="burger-content">
            <div class="burger-links">
                <router-link class="router-link" to="/">
                    <span>Inicio</span>
                </router-link>
                <router-link class="router-link" to="/about">
                    <span>Conocenos</span>
                </router-link>
                <router-link class="router-link" to="/servicios">
                    <span>Servicios</span>
                </router-link>
                <router-link class="router-link" to="/portafolio">
                    <span>Portafolio</span>
                </router-link>
                <router-link class="router-link" to="/contacto">
                    <span>Contacto</span>
                </router-link>
            </div>
            <div class="burger-footer">
                <a href="#"><span><img class="link-icon2" src="./css/assets/facebook.png"/> Facebook</span><br><span><img class="link-icon" src="./css/assets/facebook.png"/> Facebook</span></a>
                <br>
                <a href="#"><span><img class="link-icon2" src="./css/assets/instagram.png"/> Instagram</span><br><span><img class="link-icon" src="./css/assets/instagram.png"/> Instagram</span></a>
                <br>
                <a href="#"><span><img class="link-icon2" src="./css/assets/tiktok.png"/> Tiktok</span><br><span><img class="link-icon" src="./css/assets/tiktok.png"/> Tiktok</span></a>
                <br>
                <a href="#"><span><img class="link-icon2" src="./css/assets/In.png"/> Linkedin</span><br><span><img class="link-icon" src="./css/assets/In.png"/> Linkedin</span></a>
                <p>TODOS LOS DERECHOS RESERVADOS | ©ACALLI'S DESIGN 2024</p>
            </div>
        </div>-->
  <router-view>
  </router-view>

        <div id="main-footer">
            <div class="f-section">
                <div class="a-element">
    
                    <a class="rotating-element footer-a" href="https://wa.me/8120693781?text=Hola me podria ayudar con una cotizacion">
                        
                        <div class="rotating-text">
                            <i class="fa fa-whatsapp"></i>
                            <span class="r1">8<br>8</span>
                            <span class="r2">1<br>1</span>
                            <span class="r3">&nbsp;<br>&nbsp;</span>
                            <span class="r4">2<br>2</span>
                            <span class="r5">0<br>0</span>
                            <span class="r6">6<br>6</span>
                            <span class="r7">9<br>9</span>
                            <span class="r8">&nbsp;<br>&nbsp;</span>
                            <span class="r9">3<br>3</span>
                            <span class="r10">7<br>7</span>
                            <span class="r11">8<br>8</span>
                            <span class="r12">1<br>1</span>
                        </div>
                    </a>
                </div>
                <div class="a-element">
                    <a class="rotating-element footer-a" href="mailto:acallisdesign@gmail.com ?">
                        <div class="rotating-text">
                            <i class="fa fa-envelope-square"></i>
                            <span class="r1">ac<br>ac</span>
                            <span class="r2">al<br>al</span>
                            <span class="r3">li<br>li</span>
                            <span class="r4">sd<br>sd</span>
                            <span class="r5">es<br>es</span>
                            <span class="r6">ig<br>ig</span>
                            <span class="r7">n@<br>n@</span>
                            <span class="r8">gm<br>gm</span>
                            <span class="r9">ai<br>ai</span>
                            <span class="r10">l.<br>l.</span>
                            <span class="r11">co<br>co</span>
                            <span class="r12">m<br>m</span>
                        </div>
                    </a>
                </div>
            </div>
            <div class="f-section">
                <img class="logo" src="./css/assets/imagenessitioAcallis/logo Acallis circ.gif"/>
            </div>
            <div class="f-section">
                <div class="mini-networks">
                    <a href="#">
                        <img class="link-icon" src="./css/assets/mundo.png"/>
                    </a>
                    <a href="https://www.facebook.com/AcallisDesign">
                        <img class="link-icon" src="./css/assets/facebook.png"/>
                    </a>
                    <a href="https://www.instagram.com/acallisdesign?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                        <img class="link-icon" src="./css/assets/instagram.png"/>
                    </a>
                    <a href="https://www.tiktok.com/@acallisdesign">
                        <img class="link-icon" src="./css/assets/tiktok.png"/>
                    </a>
                    <a href="#">
                        <img class="link-icon" src="./css/assets/In.png"/>
                    </a>
                </div>
                <p>Acalli's Design</p>
                <img class="shade" src="./css/assets/imagenessitioAcallis/shade.png"/>
            </div>
            <p class="copyright">TODOS LOS DERECHOS RESERVADOS | ACALLI'S DESIGN COPYRIGHT © 2024</p>
        </div>
</template>

<style>
@font-face {
    font-family: Avenir;
    src: url("css/fonts/AvenirNextLTPro-Bold.otf"); 
}
@font-face {
    font-family: DIN;
    src: url("css/fonts/DIN Alternate Bold.otf"); 
}
@font-face {
    font-family: Barlow;
    src: url("css/fonts/BarlowCondensed-Regular.ttf"); 
}
@font-face {
    font-family: infinity;
    src: url("css/fonts/Beyond\ Infinity\ -\ Demo.ttf"); 
}
html{
    scroll-behavior: smooth;
}
body{
    margin: 0;
}
#app{
    perspective: 2000px;
    overflow-x: hidden;
}
p,h1,h2,h3,h4,h5,a,pre,td,th{
    font-family: Barlow;
}
.cursive-alv{
    font-family: infinity;
}
.sectionne{
    width: 100%;
    float: left;
    transform-style: preserve-3d;
    transform: rotateX(80deg) scale(1.5);
    transition: 0.3s ease-out;
    opacity: 0;
}
.first-sectionne{
    transform: none !important;
    opacity: 1 !important;
}
.rotating-text{
    height: 24px;
    font-size: 20px;
    overflow-y: hidden;
    width: 100%;
    float: left;
    color: black;
    font-weight: bold;
}
.rotating-text i{
    font-weight: normal;
}
.r1{
    transition: 0.1s ease-out;
}
.r2{
    transition: 0.2s ease-out;
}
.r3{
    transition: 0.3s ease-out;
}
.r4{
    transition: 0.4s ease-out;
}
.r5{
    transition: 0.5s ease-out;
}
.r6{
    transition: 0.6s ease-out;
}
.r7{
    transition: 0.7s ease-out;
}
.r8{
    transition: 0.8s ease-out;
}
.r9{
    transition: 0.9s ease-out;
}
.r10{
    transition: 1s ease-out;
}
.r11{
    transition: 1.1s ease-out;
}
.r12{
    transition: 1.2s ease-out;
}
.r13{
    transition: 1.3s ease-out;
}
.r14{
    transition: 1.4s ease-out;
}
.r15{
    transition: 1.5s ease-out;
}
.rotating-text i{
    float: left;
    margin-right: 8px;
}
.rotating-text span{
    float: left;
}
.rotating-text span br{
    height: 1px;
}
.rotating-element:hover .rotating-text span{
    margin-top: -26px;
}


#main-nav{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 16px;
    z-index: 9;
}
#main-nav .logo{
    float: left;
    width: 50%;
    height: 32px;
}
#nav-links{
    float: left;
    width: 50%;
    padding: 0;
}
#nav-links .a-element{
    list-style-type: none;
    text-align: center;
    float: left;
    width: 20%;
    overflow-x: hidden;
}
#nav-links .a-element .rotating-element .rotating-text{
    color: #B29774;
    letter-spacing: 2px;
}

#main-footer{
    float:left;
    width: 90%;
    background-image: url('css/assets/imagenessitioAcallis/footer Acallis-min.jpg');
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 6px;
    padding-top: 69px;
    padding-left: 5%;
    padding-right: 5%;
}
.f-section .a-element .rotating-element .rotating-text{
    color: white;
    margin: 6px 0 6px 0;
}
.f-section{
    width: 33.3333%;
    float: left;
}
.f-section .logo{
    filter: brightness(1000%);
    width: 104px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: -51px;
}
.copyright{
    color: white;
    width: 100%;
    float: left;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
}
.f-section p{
    width: 100%;
    float: left;
    color: white;
    text-align: center;
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 0;
    margin-top: 6px;
}
.mini-networks{
    color: white;
    text-decoration: none;
    width: 100%;
    float: left;
    text-align: center;
    letter-spacing: 3px;
}
.mini-networks a{
    transition: 0.2s ease-in-out;
    color: white;
    text-decoration: none;
}
.mini-networks a:hover{
    filter: brightness(60%);
}
.f-section .shade{
    float: left;
    width: 113px;
    margin-left: 50%;
    transform: translateX(-50%);
    filter: brightness(0);
}
#nav-logo{
    width: 164px;
    padding-left: 9.5%;
}
.burger-btn{
    position: fixed;
    right: 2.5%;
    display: none;
    z-index: 9;
    margin-top: 16px;
}
.burger-btn .rotating-text{
    font-weight: bolder;
    transform: scale(1.05);
    z-index: 9;
}
.burger{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 100%;
    z-index: 7;
    background-color: #210B49;
}
.burger-content{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    z-index: 8;
    display: none;
}
.amazing-name-burger{
    line-height: 1.1;
    color:white;
    pointer-events: none;
    font-size: 43px;
    position: fixed;
    top:46%;
    left: 1%;
    transform: translate(0,-50%);
    z-index: 0;
    height: 51px;
    overflow-y: hidden;
}
.amazing-name-burger span{
    margin-top: 45px;
    float: left;
    transition: 0.4s ease-out;
}
.burger-footer{
    width: 98%;
    position: fixed;
    bottom: 1%;
    right: 1%;
    text-align: right;
    opacity: 0;
    transition: 0.4s ease-out;
}
.burger-footer p{
    color: white;
    font-size: .875rem;
    line-height: 1.4;
}
.burger-footer p{
    text-align: center;
    float: left;
    width: 100%;
}
.burger-footer a,.rotate-this{
    color: white;
    text-decoration: none;
    font-weight: bold;
    margin-left: 50%;
    transform: translate(-50%,0);
    float: left;
    text-align: center;
    height: 20px;
    overflow-y: hidden;
    margin-bottom: 10px;
}
.burger-links{
    width: 96.5%;
    margin-top: 142px;
    margin-left: 3.5%;
}
.burger-links a{
    color: white;
    font-size: 42px;
    height: 42px;
    text-decoration: none;
    width: 100%;
    float: left;
    overflow-y: hidden;
    margin-bottom: 16px;
}
.burger-links a span{
    margin-top: 47px;
    float: left;
    transition: 0.4s ease-out;
}
.link-icon{
    width: 20px;
}
.link-icon2{
    width: 17px;
    transform: translateY(3px);
}

#content{
    width: 100%;
    min-height: 200px;
}
.active{
    filter: brightness(0.5);
}
#main-nav.sticky{
    background-color:white;
}

@media only screen and (max-width : 900px) {
    #nav-links{
        display: none;
    }
    #nav-logo{
        width: 94px;
        padding: 0;
        margin-top: -16px;
        transition: 0.2s ease-out;
    }
    .burger-btn{
        display: initial;
    }
    #main-footer{
        padding-left:0;
        padding-right: 0;
        padding-top: 10px;
        width:100%;
    }
    #main-footer .f-section:nth-child(2){
        display: none;
    }
    #main-footer .f-section{
        width: 100%;
        margin-top: 14px;
    }
    #main-footer .f-section .a-element{
        width: fit-content;
        margin-left: 50%;
        transform: translateX(-50%) scale(0.85);
    }
    #main-footer .f-section p{
        display: none;
    }
    #main-footer .f-section .mini-networks{
        letter-spacing: 12px;
    }
}
</style>

<script>
import { ref, computed, onMounted } from 'vue';
import $ from 'jquery'

export default {
  name: 'App',
  setup() {
    // State
    const active = ref(false);

    // Computed class name
    const toggleNavClass = computed(() => {
      return active.value ? 'sticky-nav' : 'nav';
    });

    // Scroll listener
    onMounted(() => {
      const navBar = document.getElementById('main-nav');
      $.fn.isInViewport = function() {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();
        
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();
        
            return elementBottom > viewportTop && elementTop < viewportBottom;
        };
      function showSect(){
        $('.sectionne').each(function(){
            if($(this).isInViewport()){
                setTimeout(() => {
                    $(this).css({'transform':'rotateX(0deg)','opacity':'1'});
                }, 10);
            }
        });
      }
      window.onscroll = () => {
        $(navBar).css({'margin-top':window.scrollY});
        showSect();
        /*if (window.scrollY > navBar.offsetTop) {
          active.value = true;
        } else {
          active.value = false;
        }*/
      };
    });

    return {
      active,
      toggleNavClass,
    };
  },
};

</script>